@font-face {
  font-family: FreightSans;
  src: url('../../assets/FreightSansProBook-Regular.ttf');
}

.bottom-card {
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.bottom-card-expanded {
  padding-top: 16px;
  border-bottom: 1px solid rgb(240, 240, 240);
}

.bottom-card .ant-btn .anticon.anticon-heart {
  color: #E60D7D;
  transition: all 0.15s;
}

.bottom-card .ant-btn:hover .anticon.anticon-heart {
  color: #df599e;
}

.text-post {
  display: flex;
  font-family: FreightSans;
  font-size: 24px;
  line-height: normal;
  font-weight: 600;
  width: "100%";
}

.text-title {
  font-size: 18px;
}

.braft-output-content p {
  margin: 0;
}

.post-card {
  background: url("../../assets/PostCardBackground.png");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% auto;
  background-blend-mode: multiply;
  container-type: inline-size;
}

.background-claim {
  background-image: url("../../assets/PostCardBackground.png"), linear-gradient(90deg, rgba(238,115,98,.5) 0%, rgba(232,62,121,.5) 35%, rgba(184,105,167,.5) 67%, rgba(77,150,209,.5) 96%);
}

.row-avatars {
  flex-wrap: nowrap;
}

@container (max-width: 500px) {
  .row-avatars {
    flex-wrap: wrap;
  }
}

@container (max-width: 350px) {
  .text-title,
  .text-post {
    font-size: 16px;
  }

  .text-post {
    flex-direction: column;
  }

  .post-card .ant-typography blockquote {
    padding-inline: 0;
    padding-left: 0px;
  }
}