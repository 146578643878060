.profile-page .ant-avatar.ant-avatar-image::after {
  content: "+";
  white-space: pre;
  text-align: center;
  font-size: 15px;
  line-height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0;
  transition: all 0.2s ease-in-out;
  width: 100%;
  height: 100%;
}

.profile-page .ant-avatar.ant-avatar-image:hover::after {
  opacity: 1;
}
