.CreatePostCard .ant-input-number-group .ant-input-number-group-addon {
  border: none;
}

.usergroup-icon {
  border-radius: 50%;
  cursor: pointer;
  font-size: 20px;
  margin-top: 6px;
  padding: 1px;
}

.usergroup-icon:hover,
.active {
  color: #3488ff;
}

.clear-icon:active {
  color: #3488ff;
}