.card {
    justify-content: center;
    width: 100%;
}

@container (min-width: 1000px) {
    .card {
        margin: 0px auto;
        width: 60%;
    }
}