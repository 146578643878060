.myredeems-page .ant-list-item .ant-list-item-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.myredeems-page .ant-list-item-extra {
  max-width: 150px;
  max-height: 150px;
  aspect-ratio: 1 / 1;
  border-radius: 5px;
  overflow: hidden;
  order: -1;
  margin-inline-start: 0 !important;
}

@media screen and (min-width: 576px) {
  .myredeems-page .ant-list-item-extra {
    order: 0;
    margin-inline-start: 24px;
    margin-inline-end: 0;
  }
}
