.mobile-menu.ant-menu::before,
.mobile-menu.ant-menu::after {
  display: none;
}

.mobile-menu .ant-menu-item {
  padding: 0;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 100%;
}

.mobile-menu .ant-menu-item.feed {
  flex-basis: 115%;
}

.mobile-menu .ant-menu-item.feed::before {
  content: "";
  height: 8px;
  width: 75%;
  background-color: #fff;
  position: absolute;
  top: -8px;
  border-top: 1px solid #f0f0f0;
  border-left: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  transform: perspective(10px) rotateX(10deg);
}

.mobile-menu .ant-menu-item-active .anticon,
.mobile-menu
  .ant-menu-item-active
  .ant-menu-title-content
  .nav-link
  .ant-typography {
  color: #2966c6;
}

.mobile-menu .ant-menu-item .ant-menu-title-content {
  width: 100%;
  height: 100%;
}

.mobile-menu .ant-menu-item .ant-menu-title-content .nav-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  row-gap: 5px;
}

.mobile-menu .ant-menu-item .ant-menu-title-content .nav-link .ant-typography {
  font-size: 9px;
  line-height: 1em;
  margin-inline-start: 0;
}

.mobile-menu .ant-menu-item .ant-menu-title-content .nav-link .anticon {
  font-size: 22px;
}
